.App {
  background-color: #404040;
  height: 100vh;
  min-height: 100%;
	display: flex;
  flex-direction: column;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.App-header {
  background-color: #303030;
  min-height: 50px;
  height: auto;
  display: flex;
  align-content: center;
  padding: 10px;
}

.App-Search {
  background-color: #303030;
  height: auto;
  align-content: center;
}

.App-body {
  background-color: #404040;
  min-height: 100%;
  height: fit-content;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Header-button {
  width: 20%;
  margin: 1px;
  height: 50px;
  border: none;
  background-color: #0077C0;
  color: #ECF0F1;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  outline:0;
}

.Header-button:hover { 
  background-color: #0067B0;

}

.Header-button:active { 
  background-color: #0057A0;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.Header-button-menu, .Header-button-search {
  width: 50px;
  height: 50px;
  margin: 1px;
  border: none;
  background-color: #0077C0;
  color: #ECF0F1;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  background-size: 30px 30px;
  background-position: center center;
  outline:0;
}

.Header-button-menu:hover, .Header-button-search:hover { 
  background-color: #0067B0;

}

.Header-button-menu:active, .Header-button-search:active { 
  background-color: #0057A0;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

html, body, #app, #app>div {
  height: 100%
}

.Footer-Bottom {
  width: 100%;
  align-content: center;
  align-items: center;
  text-align: center;
}

.Footer-Link {
  font-size: 13px;
  padding: 0px;
  height: 13px;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 10px;
  width: auto;
  color: #ECF0F1;
  text-decoration: underline;
  cursor: pointer;
}

.Footer-Link-Anchor {
  color: #ECF0F1;
}

.Footer-Link:hover, .Footer-Link-Anchor:hover {
  color: #FFCF4B;
}

.Footer-Link:active, .Footer-Link-Anchor:active {
  color: #F9B32F;
}

.Copyright {
  width: fit-content;
  color: #ECF0F1;
  width: 98%;
  height: 30px;
  align-self: center;
  text-align: center;
  font-size: 12px;
}

.Page-Division {
  width: 100%;
  height: 100%;
  background-color: #404040;
  height: max-content;
}

.Page-Inside {
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: calc(100vh - 300px);
}

.Page-Inside-Clear {
  background-color: transparent;
  margin-left: 5%;
  margin-right: 5%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  min-height: calc(100vh - 300px);
}

.PP-Heading {
  margin-top: 0px;
  padding-top: 20px;
  color: #ECF0F1;
  font-size: 20px;
  font-weight: bold;
}

.PP-SubHeading {
  font-size: 15px;
  color: #ECF0F1;
  text-decoration: underline;
  font-weight: bold;
  size: 15px;
  margin-bottom: 0px;
  padding: 0px;
  height: 13px;
}

.PP-Text {
  font-size: 15px;
  color: #ECF0F1;
  text-align: justify;
  margin-bottom: 10px;
}

.Home-Page {
  background-color: #404040;
}

.Home-Page-Bottom {
  align-items: center;
  justify-content: center;
  
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  width: 80%;
  height: 90%;
  margin: 0 auto;
  padding: 0px;
  background-color: #404040;
  height: max-content;
}

.homeDivisionTitleContainer {
  align-items: left;
  justify-content: left;
  
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  width: 80%;
  height: 90%;
  margin: 0 auto;
  background-color: #404040;
  height: max-content;
}

.homeDivisionTitle {
  color: #ECF0F1;
  font-size: 30px;
  margin: 0px;
  font-weight: bold;
}

.Home-Heading {
  color: #ECF0F1;
  font-size: 50px;
  text-align: center;
}

.cardBox {
  perspective: 330px;
  margin: 10px;
}

.card {
  position: relative;
  width: 330px;
  height: 250px;
  background-color: rgba(255, 255, 255, 0.6);
  transform-style: preserve-3d;
  transition: .5s ease;
  box-shadow: 0 30px 30px rgba(0, 0, 0, .5);
  backface-visibility: hidden;
  border-radius: 10px;
}

.card-front, .card-back {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  border-radius: 10px;
}

.card-back {
  width: 330px;
  height: 250px;
  backface-visibility: hidden;
}

.card-front {
  width: 330px;
  height: 250px;
  font-size: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 10px;
}

.card-back {
  font-size: 30px;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  box-shadow: 0 30px 30px rgba(0, 0, 0, .5);
  text-align: center;
}

.cardBox:hover .card {
  transform: rotateY(180deg);
}

.card-back {
  transform: rotateY(180deg);
}

.card-front-div {
  width: 310px;
  height: 190px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.card-image-front {
  max-width: 100%;
  max-height: 100%;
}

.card-title-front {
  font-size: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  padding-top: 10px;
}

.card-image-back {
  max-width: 40%;
  max-height: 40%;
  padding-top: 10%
}

.card-title-back {
  font-size: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  padding-top: 50px;
  color: #ECF0F1;
}

.cardEnlarged {
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  transform-style: preserve-3d;
  transition: .5s ease;
  box-shadow: 0 30px 30px rgba(0, 0, 0, .5);
  backface-visibility: hidden;
  border-radius: 10px;
}

.cardEnlargedTop {
  width: 100%;
  height: auto;
  display: flex;
}

.cardEnlargedTitle {
  width: calc(100% - 30px);
  text-align: center;
  margin: 0px;
  padding-left: 30px;
}

.cardEnlargedTitleDivision {
  width: calc(100%);
  text-align: left;
  margin: 0px;
}

.cardEnlargedClose {
  width: 40px;
  height: 40px;
  border:1px;
  background-color: #D93829;
  color: #ECF0F1;
  border-radius: 100%;
  font-weight: bold;
  font-size: 100%;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  text-align: center;
  padding: 0px;
  outline:0;
}

.cardEnlargedClose:hover {
  background-color: #C72C1C;
}

.cardEnlargedClose:active {
  background-color: #A70C00;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.Home-Line {
  width: 80%;
}

.App-Info {
  margin-left: 10px;
}

.App-Link {
  margin-right: 20px;
  color: #FFCF4B;
  text-decoration: underline;
}

.appLinkHome {
  margin-right: 20px;
  color: #212121;
  background-color: #D6D6D6;
  text-decoration: underline;
  font-weight: bold;
  border-radius: 16px;
  padding: 8px 20px;
  text-decoration: none;
  background-size: 300px 100px;
  width: 120px;
  display:block;
  text-align: center;
  margin-top: 16px;
}

.appLinkHome:hover {
  background-color: #C1C1C1;
}

.App-Links-Home {
  display:flex;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 16px;
}

.Profile-Division {
  background-color: #303030;
  width: 80%;
  display: flex;
  margin: 0 auto;
  margin-top: 16px;
  border-radius: 16px;
  padding: 16px;
  height: auto;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
}

.cardDivision {
  background-color: #303030;
  position: relative;
  width: 330px;
  height: 250px;
  transform-style: preserve-3d;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  margin: 0 auto;
  border-radius: 16px;
  padding: 8px;
  transition: 0.3s;
}

.cardDivision:hover {
  background-color: #404040;
  position: relative;
  width: 330px;
  height: 250px;
  transform-style: preserve-3d;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  margin: 0 auto;
  border-radius: 16px;
  padding: px;
  transition: 0.3s;
}

.cardDivisionEnlarged {
  background-color: #303030;
  position: relative;
  width: 100%;
  height: fit-content;
  transform-style: preserve-3d;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  margin: 0 auto;
  border-radius: 16px;
  padding: 8px;
  transition: 0.3s;
}

.cardIcon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.cardIconEnlarged {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}

.cardImage {
  max-width: 90%;
  max-height: 90%;
  margin-bottom: 16px;
  margin: auto;
  display: block;
  max-height: 220px;
}

.cardsImage {
  max-width: 90%;
  max-height: 90%;
  margin-bottom: 16px;
  margin: auto;
  display: block;
  max-height: 180px;
}

.cardTitle {
  color: #ECF0F1;
  font-size: 16px;
  margin: 0px;
  font-weight: bold;
}

.cardSubtitle {
  color: #ECF0F1;
  font-size: 14px;
  margin: 0px;
}

.cardTitleEnlarged {
  color: #ECF0F1;
  font-size: 26px;
  margin: 0px;
  font-weight: bold;
}

.cardSubtitleEnlarged {
  color: #ECF0F1;
  font-size: 20px;
  margin: 0px;
}

.cardImagesHorizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  color: #ECF0F1;
}

.horizontalDivision {
  display: flex;
}

.Profile-Details {
  width: 70%;
  height: 100%;
}

.Profile-Links-Division {
  width: 100%;
  height: 30px;
  display: flex;
  margin-bottom: 10px;
}

.Profile-Link-Image {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin-right: 10px;
}

.Profile-Heading {
  color: #ECF0F1;
  font-size: 50px;
  margin: 0px;
  font-weight: bold;
}

.Profile-Subheading {
  color: #ECF0F1;
  font-size: 20px;
  margin: 0px;
}

.About-Division {
  background-color: #303030;
  width: 80%;
  height: auto;
  margin: 0 auto;
  margin-top: 2px;
  border-radius: 10px;
  padding: 10px;
}

.About-Heading {
  color: #ECF0F1;
  font-size: 25px;
  margin: 0px;
  font-weight: bold;
}

.About-Text {
  color: #ECF0F1;
  font-size: 15px;
  margin: 0px;
  text-align: left;
}

.Contact-Title {
  margin-top: 0px;
  padding-top: 20px;
  color: #ECF0F1;
  font-size: 20px;
  font-weight: bold;
}

.Contact-Text {
  color: #ECF0F1;
}

.Contact-Text-Link {
  color: #39D5FF;
}

/*small screen design*/
@media only screen and (max-width: 770px) {
  .App-Icon {
    margin-top: 10px;
    max-width: 70px;
    max-height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .App-Title {
    color: #ECF0F1;
    font-size: 20px;
    margin-left: 5px;
    height: min-content;
    font-weight: bold;
  }
  
  .App-Features {
    color: #ECF0F1;
    font-size: 15px;
    padding-left: 25px;
  }

  .App-footer {
    background-color: #303030;
    width: 100%;
    bottom:0px;
    height: fit-content;
  }

  .Footer-Top {
    width: 100%;
    height: fit-content;
    align-content: center;
  }

  .Footer-Div {
    width: 100%;
    margin-bottom: 15px;
    float: left;
  }

  .Footer-line {
    width: 80%;
    align-self: center;
  }

  .Footer-Heading {
    padding-top: 10px;
    font-size: 20px;
    height: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    color: #ECF0F1;
  }
  
  .Profile-Image {
    height: 70px;
    width: auto;
    padding: 10px;
    border-radius: 100%;
    margin-left: auto;
  }

  .App-Page-Top {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align:center;
  }

  .Download-Icon-Link, .Toggle-Div {
    display:block; 
    width:fit-content; 
    margin:0 auto;
  }

  .Download-Icon-Link-Home {
    display:block; 
    width:200px; 
    margin:0 auto;
  }

  .Header-button {
    display: none;
  }

  .App-Link {
    font-size: 12px;
  }

  .cardEnlargedTitle {
    font-size: 20px;
  }

  .PreviousImageButtonHome, .NextImageButtonHome {
    visibility: hidden;
    display: none;
  }

  .App-Links-Home {
    display:block;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 16px;
  }

  .cardTitleEnlarged {
    font-size: 20px;
  }

  .cardSubtitleEnlarged {
    color: #ECF0F1;
    font-size: 16px;
  }

  .cardIconEnlarged {
    width: 56px;
    height: 56px;
  }
}

/*large screen design*/
@media only screen and (min-width: 771px) {
  .App-Icon {
    margin-top: 20px;
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }

  .App-Main {
    display: flex;
  }

  .App-Title {
    color: #ECF0F1;
    font-size: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
  
  .App-Features {
    color: #ECF0F1;
    font-size: 15px;
  }

  .App-footer {
    background-color: #303030;
    width: 100%;
    bottom:0px;
  }

  .Footer-Top {
    display: flex;
    width: 100%;
    align-content: center;
  }

  .Footer-Div {
    width: 33%;
    margin: 1px;
    padding-left: 20px;
  }

  .Footer-line {
    width: 80%;
    align-self: center;
  }

  .Footer-Heading {
    font-size: 20px;
    height: 20px;
    width: 80%;
    margin: 5px;
    margin-left: 10px;
    color: #ECF0F1;
  }

  .Profile-Image {
    height: 160px;
    width: auto;
    padding: 10px;
    border-radius: 100%;
    margin-left: auto;
  }

  .App-Page-Top {
    display: flex;
    width: calc(100% - 100px);
  }

  .Header-button-menu {
    display: none;
  }

  .headerMenu {
    display: none;
  }

  .App-Link {
    font-size: 15px;
  }

  .Download-Icon-Link-Home {
    display:block; 
    width:200px; 
    margin:0 auto;
  }

  .cardEnlargedTitle {
    font-size: 30px;
  }
}

.App-Page-Title {
  color: #ECF0F1;
  font-size: 30px;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.Download-Icon {
  margin-top: 20px;
  height: 50px;
}

.Download-Icon-Home {
  margin-top: 20px;
  height: 50px;
}

.Download-Icon-Home:hover {

  filter: brightness(50%);
  -webkit-filter: brightness(50%);
  -moz-filter: brightness(50%);
  -o-filter: brightness(50%);
  -ms-filter: brightness(50%);
  transition: .5s ease;
}

.App-Image-List {
  margin-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.App-Image-Enlarged {
  margin-top: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.App-Image-Gallery {
  max-width: 250px;
  max-height: 500px;
  width: auto;
  margin: 1px;
}

.App-Image-Gallery:hover {
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
  -moz-filter: brightness(50%);
  -o-filter: brightness(50%);
  -ms-filter: brightness(50%);
  transition: .5s ease;
}

.App-Image-Large {
  max-height: 700px;
  width: auto;
  max-width: 80%;
}

.App-Image-Large-Home {
  max-width: 70%;
  max-height: 500px;
}

.App-Description {
  color: #ECF0F1;
  font-size: 15px;
  margin: 0px;
  text-align: justify;
}

.ExpandButton, .CollapseButton, .ToggleOS  {
  width: 150px;
  height: 30px;
  border-style: none;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #ECF0F1;
  border-style: solid;
  border-color: #ECF0F1;
  border-width: 1px;
  outline:0;
}

.osSegment {
  width: 150px;
  height: 35px;
  border-style: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  color: #ECF0F1;
  background-color: #ECF0F1;
  border-style: solid;
  border-color: #ECF0F1;
  border-width: 1px;
  outline:0;
  font-size: 12px;
}

.PreviousImageButton, .NextImageButton {
  width: 20px;
  height: 100px;
  border-style: none;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #ECF0F1;
  border-style: solid;
  border-color: #ECF0F1;
  border-width: 1px;
  outline:0;
}

.PreviousImageButtonHome, .NextImageButtonHome {
  width: 30px;
  height: 80px;
  color: #212121;
  background-color: #D6D6D6;
  font-weight: bold;
  border-radius: 16px;
  padding: 8px 8px;
  border: none;
}

.PreviousImageButtonHome:hover, .NextImageButtonHome:hover {
  background-color: #C1C1C1;
}

.PreviousImageButtonHome:active, .NextImageButtonHome:active {
  background-color: #D1D1D1;
}

.ExpandButton:hover, .CollapseButton:hover, .PreviousImageButton:hover, .NextImageButton:hover, .ToggleOS:hover{
  background-color: rgba(0, 0, 0, 0.3);
}

.ExpandButton:active, .CollapseButton:active, .PreviousImageButton:active, .NextImageButton:active, .ToggleOS:active {
  background-color: rgba(0, 0, 0, 0.4);
}

.headerMenu {
  align-self: center;
  align-items: center;
  float: left;
  width: calc(100% - 50px);
}

.headerMenuItem {
  width: 100%;
  height: 30px;
  border: none;
  background-color: #0077C0;
  font-size: 12px;
  margin-top: 1px;
  color: #ECF0F1;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.headerMenuItem:hover {
  background-color: #0067B0;
}

.headerMenuItem:active {
  background-color: #0057A0;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.searchMenu {
  margin-left: 10px;
  margin-right: 10px;
  height: fit-content;
}

.Search-Div {
  display:block; 
  width: 90%; 
  margin:0 auto;
}

.search-field {
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  background-size: 20px 20px;
  background-position: center left;
  background-position-x: 5px;
  color: #ECF0F1;
  border-radius: 10px;
  border: none;
  text-indent: 30px;
  margin-bottom: 10px;
  border-color: #ECF0F1;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.search-field:focus {
  outline: 0;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 255, 255, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 5px rgba(255, 255, 255, 0.6);
}

.Search-List {
  width: fit-content;
  background-color: transparent;
  text-align: left;
  color: #ECF0F1;
  font-size: 14px;
}

.Search-List:hover {
  color: #FDE3A7;
}

.Search-List:active {
  color: #FFCF4B;
}

.Search-List-Div {
  width: 100%;
  height: fit-content;
  margin-bottom: 8px;
}